import { ScrollArea, ScrollBar } from "@/components/ui/scroll-area.tsx";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table.tsx";
import {
  ColumnSettingsByColumnNameInOrder,
  POPUP_COLUMNS,
} from "@/hooks/ColumnSettingsByColumnName/ColumnSettingsByColumnNameInOrder.tsx";
import {
  checkStepPositionByName,
  ONBOARDING_CLASSES,
} from "@/components/onboarding/useOnboarding.tsx";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card.tsx";
import { SortDropdown } from "@/components/sort/SortDropdown.tsx";
import { makeLinkFromAsin } from "@/helpers/helpers.ts";
import { Checkbox } from "@/components/ui/checkbox.tsx";
import { Button } from "@/components/ui/button.tsx";
import ChartExpander from "@/components/ChartExpander/ChartExpander.tsx";
import { formatNumber } from "@/lib/utils.ts";
import { TopProductPopover } from "@/components/TopProductPopover/TopProductPopover.tsx";
import React, { forwardRef, useEffect } from "react";
import { SortColumnOption } from "@/pages/TrendsPage/TrendsPage.tsx";
import { useOnboardingStore } from "@/components/onboarding/useOboardingStore.tsx";
import { FilteredColumns } from "@/hooks/usePaginatedTrends/types/FilteredColumns.ts";
import { ProgressLoader } from "@/components/ProgressLoader/ProgressLoader.tsx";
//tdoo
const TrendsTable = forwardRef<
  React.ElementRef<never>,
  {
    tableHeadRow: any;
    hiddenRowsIds: FilteredColumns[];
    appliedSort: SortColumnOption[];
    addSort: ({ sort }: { sort: SortColumnOption[] }) => void;
    paginatedData: any[];
    isFetching: boolean;
    isPlaceholderData: boolean;
    removeSelectedRowId: (id: number) => void;
    addSelectedRowId: (id: number) => void;
    selectedRows?: number[];
    isLoading?: boolean;
  }
>(
  (
    {
      tableHeadRow,
      hiddenRowsIds,
      appliedSort,
      addSort,
      paginatedData,
      isFetching = false,
      isPlaceholderData = false,
      addSelectedRowId,
      removeSelectedRowId,
      selectedRows,
      isLoading = false,
    },
    ref,
  ) => {
    const filteredData = paginatedData || [];

    const isNegative = (value: string) => {
      return value.startsWith("-");
    };

    const { nextStep, onboardingCanShow, currentOnboardingStep } =
      useOnboardingStore();

    useEffect(() => {
      const canAdd =
        [
          checkStepPositionByName(
            ONBOARDING_CLASSES.watchListTrendCheckboxClick,
          ),
        ].includes(currentOnboardingStep) && onboardingCanShow;

      if (canAdd) {
        addSelectedRowId(paginatedData[4].search_term_id);
      }
    }, [
      addSelectedRowId,
      currentOnboardingStep,
      paginatedData,
      onboardingCanShow,
    ]);

    const ValueDisplay = ({ value }) => {
      const negative = isNegative(value);
      const hasPercentage = value.includes("%");
      const numericValue = hasPercentage
        ? parseFloat(value.slice(0, -1))
        : parseFloat(value);
      const finalValue = formatNumber({
        number: numericValue,
        isPercentage: hasPercentage,
      });

      return (
        <p className={`${negative ? "text-red-800" : "text-green-800"}`}>
          {hasPercentage ? finalValue : value}
        </p>
      );
    };

    return (
      <ScrollArea
        ref={ref}
        className="max-w-[100vw] h-screen max-h-full"
        type={"always"}
      >
        <div>
          {((isFetching && isPlaceholderData) || isLoading) && (
            <div className={"absolute z-[9999]   w-full"}>
              <ProgressLoader />
            </div>
          )}

          <Table>
            <TableHeader className={`${ONBOARDING_CLASSES.tableHeaderStep}`}>
              <TableRow>
                <TableHead
                  className={`sticky left-0 z-[29] border-t -top-[3px]  text-black text-center font-xs text-[12px] border-r-gray-300 bg-gray-50 border-r w-auto`}
                >
                  Add
                </TableHead>
                {tableHeadRow.map((column, i) => {
                  const hiddenRow =
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    ColumnSettingsByColumnNameInOrder?.[column]?.hidden;
                  const sortable =
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    ColumnSettingsByColumnNameInOrder?.[column]?.sortable;
                  const isHidden = hiddenRow || hiddenRowsIds.includes(column);
                  const shownColumnName =
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    ColumnSettingsByColumnNameInOrder?.[column]?.shownName;

                  const tooltipText =
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    ColumnSettingsByColumnNameInOrder?.[column]?.tootipText;

                  const columnIsSorted = appliedSort.some(
                    (s) => s.columnKey === column,
                  );

                  const isNewKey = column === "new_key";
                  const isSvChange = column === "sv_change";
                  const isSearchTerm = column === "search_term";
                  return (
                    <TableHead
                      key={`${column}-${i}`}
                      className={`${isHidden || Object.keys(POPUP_COLUMNS).includes(column) ? "hidden" : ""} 
                      ${columnIsSorted && "!bg-blue-100"}
                   text-black text-center font-xs font-normal border-t bg-gray-50 border-r border-r-gray-300 sticky -top-[3px] !z-[1] ${isSvChange ? "!p-0 !px-0 !mx-0 w-[290px]" : "py-[6px]"} w-auto`}
                    >
                      {isSvChange ? (
                        <div className="h-[92px] flex flex-col justify-between w-[288px]">
                          <span
                            className={
                              "text-xs mt-1 cursor-default font-medium"
                            }
                          >
                            {shownColumnName || column}
                          </span>
                          <div
                            className={`${ONBOARDING_CLASSES.searchVolumeSortsHighlight} flex flex-row w-full justify-start`}
                          >
                            <div className={`flex flex-col w-[96px]`}>
                              <HoverCard openDelay={500}>
                                <HoverCardTrigger className="text-xs w-24 font-medium text-text-2 mb-1 cursor-help">
                                  12 mo
                                </HoverCardTrigger>
                                <HoverCardContent
                                  side="left"
                                  className="w-max-96"
                                >
                                  The change in the total number of searches
                                  over the past 12 months (0-12 months ago)
                                  compared to the prior 12 months (12-24 months
                                  ago).
                                </HoverCardContent>
                              </HoverCard>
                              <TableRow className="flex flex-row border-none">
                                <div
                                  className={`text-black text-center text-xs font-normal bg-gray-50 border-r border-r-gray-200 border-t sticky top-[0px] !z-[1] py-[6px] max-w-12 min-w-12 ${appliedSort.some((s) => s.columnKey === "sv_increase_12_mnth_ratio") && "!bg-blue-100"}`}
                                >
                                  <SortDropdown
                                    valueLabel="%"
                                    queryKey="sv_increase_12_mnth_ratio"
                                    onSelect={addSort}
                                    options={isNewKey ? "bool" : "arrow"}
                                    showTriggerContent
                                    currentSort={appliedSort}
                                  />
                                </div>
                                <div
                                  className={`text-black text-center text-xs font-normal bg-gray-50 border-r border-r-gray-200 border-t sticky top-[0px] !z-[1] py-[6px] max-w-12 min-w-12 ${appliedSort.some((s) => s.columnKey === "sv_increase_12_mnth_abs") && "!bg-blue-100"}`}
                                >
                                  <SortDropdown
                                    valueLabel="#"
                                    queryKey="sv_increase_12_mnth_abs"
                                    onSelect={addSort}
                                    options={isNewKey ? "bool" : "arrow"}
                                    showTriggerContent
                                    currentSort={appliedSort}
                                  />
                                </div>
                              </TableRow>
                            </div>
                            <div className="flex flex-col w-[96px]">
                              <HoverCard openDelay={130}>
                                <HoverCardTrigger className="text-xs font-medium w-24 text-text-2 mb-1 cursor-help">
                                  6 mo
                                </HoverCardTrigger>
                                <HoverCardContent
                                  side="left"
                                  className="w-max-96"
                                >
                                  The change in the total number of searches
                                  over the past 6 months (0-6 months ago)
                                  compared to the prior 6 months (6-12 months
                                  ago).
                                </HoverCardContent>
                              </HoverCard>
                              <TableRow className="flex flex-row border-none">
                                <div
                                  className={`text-black text-center text-xs font-normal bg-gray-50 border-r border-r-gray-200 border-t sticky top-[0px] !z-[1] py-[6px] max-w-12 min-w-12 ${appliedSort.some((s) => s.columnKey === "sv_increase_6_mnth_ratio") && "!bg-blue-100"}`}
                                >
                                  <SortDropdown
                                    valueLabel="%"
                                    queryKey="sv_increase_6_mnth_ratio"
                                    onSelect={addSort}
                                    options={isNewKey ? "bool" : "arrow"}
                                    showTriggerContent
                                    currentSort={appliedSort}
                                  />
                                </div>
                                <div
                                  className={`text-black text-center text-xs font-normal bg-gray-50 border-r border-r-gray-200 border-t sticky top-[0px] !z-[1] py-[6px] max-w-12 min-w-12 ${appliedSort.some((s) => s.columnKey === "sv_increase_6_mnth_abs") && "!bg-blue-100"}`}
                                >
                                  <SortDropdown
                                    valueLabel="#"
                                    queryKey="sv_increase_6_mnth_abs"
                                    onSelect={addSort}
                                    options={isNewKey ? "bool" : "arrow"}
                                    showTriggerContent
                                    currentSort={appliedSort}
                                  />
                                </div>
                              </TableRow>
                            </div>
                            <div className="flex flex-col w-[96px]">
                              <HoverCard openDelay={130}>
                                <HoverCardTrigger className="text-xs font-medium w-24 text-text-2 mb-1 cursor-help">
                                  3 mo
                                </HoverCardTrigger>
                                <HoverCardContent
                                  side="left"
                                  className="w-max-96"
                                >
                                  The change in the total number of searches
                                  over the past 3 months (0-3 months ago)
                                  compared to the prior 3 months (3-6 months
                                  ago).
                                </HoverCardContent>
                              </HoverCard>
                              <TableRow className="flex flex-row border-none">
                                <div
                                  className={`text-black text-center text-xs font-normal bg-gray-50 border-r border-r-gray-200 border-t sticky top-[0px] !z-[1] py-[6px] max-w-12 min-w-12 ${appliedSort.some((s) => s.columnKey === "sv_increase_3_mnth_ratio") && "!bg-blue-100"}`}
                                >
                                  <SortDropdown
                                    valueLabel="%"
                                    queryKey="sv_increase_3_mnth_ratio"
                                    onSelect={addSort}
                                    options={isNewKey ? "bool" : "arrow"}
                                    showTriggerContent
                                    currentSort={appliedSort}
                                  />
                                </div>
                                <div
                                  className={`text-black text-center text-xs font-normal bg-gray-50 border-r-gray-200 border-t sticky top-[0px] !z-[1] py-[6px] max-w-12 min-w-12 ${appliedSort.some((s) => s.columnKey === "sv_increase_3_mnth_abs") && "!bg-blue-100"}`}
                                >
                                  <SortDropdown
                                    valueLabel="#"
                                    queryKey="sv_increase_3_mnth_abs"
                                    onSelect={addSort}
                                    options={isNewKey ? "bool" : "arrow"}
                                    showTriggerContent
                                    currentSort={appliedSort}
                                  />
                                </div>
                              </TableRow>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div
                          className={"flex w-full items-center justify-center"}
                        >
                          <SortDropdown
                            sortable={sortable}
                            queryKey={column}
                            onSelect={addSort}
                            options={
                              isSearchTerm
                                ? "AZ"
                                : isNewKey && !isSearchTerm
                                  ? "bool"
                                  : "arrow"
                            }
                            currentSort={appliedSort}
                          >
                            {tooltipText ? (
                              <HoverCard openDelay={100}>
                                <HoverCardTrigger>
                                  <div className="hover:opacity-60 transition">
                                    <span className={"text-xs"}>
                                      {shownColumnName || column}
                                    </span>
                                  </div>
                                </HoverCardTrigger>
                                {/*//TODO: sticky table issue*/}
                                <HoverCardContent
                                  side="left"
                                  className={" relative top-[2px] "}
                                >
                                  {tooltipText}
                                </HoverCardContent>
                              </HoverCard>
                            ) : shownColumnName === "Search Term" ? (
                              <div className="hover:opacity-60 transition">
                                <span className={"text-xs"}>
                                  {shownColumnName || column}
                                </span>
                              </div>
                            ) : (
                              <span className={"text-xs"}>
                                {shownColumnName || column}
                              </span>
                            )}
                          </SortDropdown>
                        </div>
                      )}
                    </TableHead>
                  );
                })}
              </TableRow>
            </TableHeader>
            <TableBody className={"min-h-full"}>
              {filteredData?.map((row, rowIndex) => {
                const entries: Record<string, any>[] = Object.entries(row);
                const asin = `${row["asin_1"] || row["asin_2"] || row["asin_3"]}`;
                const productLink = makeLinkFromAsin(asin);

                const inWatchList = row["in_watchlist"] || false;

                // const inBlackList = me?.black_list?.includes(id);
                const inBlackList = false;
                const isOnboardingTrend = rowIndex === 4;
                return (
                  <TableRow
                    key={row["search_term_id"] || rowIndex}
                    style={{
                      contentVisibility: "auto",
                    }}
                    className={`
                     transition-opacity  
                     ${isOnboardingTrend && ONBOARDING_CLASSES.wholeTrendStep} 
                     ${((isFetching && isPlaceholderData) || isLoading) && "animate-in opacity-50"}
                     ${inWatchList && "bg-green-600/10"} 
                     ${inBlackList && "bg-red-600/10"}`}
                  >
                    <TableCell
                      className={
                        "border-r-gray-300 border-r !font-xs !font-normal border-separate sticky left-[0px] !z-[1] bg-white"
                      }
                    >
                      <div
                        className={`${isOnboardingTrend && ONBOARDING_CLASSES.watchListTrendCheckboxClick} flex justify-center items-center w-full h-full  `}
                      >
                        <Checkbox
                          checked={selectedRows?.includes(
                            row["search_term_id"],
                          )}
                          className={"mr-2"}
                          onCheckedChange={(checked) => {
                            const id = row["search_term_id"];
                            if (checked) addSelectedRowId(id);
                            else removeSelectedRowId(id);
                            if (isOnboardingTrend) nextStep();
                          }}
                        />
                      </div>
                    </TableCell>

                    {entries.map((entry) => {
                      const cellValue = entry[1];
                      const columnName = entry[0];
                      const hiddenRow =
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        ColumnSettingsByColumnNameInOrder?.[columnName]?.hidden;
                      const isHidden =
                        hiddenRow || hiddenRowsIds.includes(columnName);

                      const cellSettings =
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        ColumnSettingsByColumnNameInOrder?.[columnName] || null;

                      const columnIsSorted = appliedSort.some(
                        (s) => s.columnKey === columnName,
                      );

                      const renderCell = () => {
                        if (columnName === "sv_change_12m") {
                          const vals = cellValue.split(" / ");

                          return (
                            <div
                              className={
                                "w-full h-[50px] flex flex-row justify-between max-w-[100px]"
                              }
                            >
                              <div
                                className={
                                  "flex justify-center items-center min-w-[49px] max-w-[49px]  text-xs"
                                }
                              >
                                <p>{vals[0]}</p>
                              </div>
                              <div
                                className={" flex min-h-full w-[1px] bg-black"}
                              />

                              <div
                                className={
                                  "flex  justify-center items-center  min-w-[49px] max-w-[49px] text-xs"
                                }
                              >
                                {vals[1]}
                              </div>
                            </div>
                          );
                        }
                        if (columnName === "sv_change") {
                          const yoyVals =
                            cellValue?.["sv_change_yoy"].split(" / ");
                          const sixMonthVals =
                            cellValue?.["sv_change_6m"].split(" / ");
                          const threeMonthVals =
                            cellValue?.["sv_change_3m"].split(" / ");

                          const isOnboardingRow = rowIndex === 4;
                          return (
                            <TableRow
                              className={`!p-0 h-[54px] flex flex-row justify-center w-[288px]
                                ${isOnboardingRow && ONBOARDING_CLASSES.searchVolumeColumns}
                            `}
                            >
                              <div className="flex flex-row">
                                <TableCell
                                  className={`flex flex-row gap-1 justify-center h-full items-center px-1 border-r w-12 text-xs ${appliedSort.some((s) => s.columnKey === "sv_increase_12_mnth_ratio") && "!bg-blue-50"}`}
                                >
                                  <ValueDisplay value={yoyVals[0]} />
                                </TableCell>
                                <TableCell
                                  className={`flex flex-row gap-1 justify-center h-full items-center px-1 border-r w-12 text-xs ${appliedSort.some((s) => s.columnKey === "sv_increase_12_mnth_abs") && "!bg-blue-50"}`}
                                >
                                  <ValueDisplay value={yoyVals[1]} />
                                </TableCell>
                              </div>
                              <div className="flex flex-row w-full">
                                <TableCell
                                  className={`flex flex-row gap-1 justify-center h-full items-center px-1 border-r w-12 text-xs ${appliedSort.some((s) => s.columnKey === "sv_increase_6_mnth_ratio") && "!bg-blue-50"}`}
                                >
                                  <ValueDisplay value={sixMonthVals[0]} />
                                </TableCell>
                                <TableCell
                                  className={`flex flex-row gap-1 justify-center h-full items-center px-1 border-r w-12 text-xs ${appliedSort.some((s) => s.columnKey === "sv_increase_6_mnth_abs") && "!bg-blue-50"}`}
                                >
                                  <ValueDisplay value={sixMonthVals[1]} />
                                </TableCell>
                              </div>
                              <div className="flex flex-row w-full">
                                <TableCell
                                  className={`flex flex-row gap-1 justify-center h-full items-center px-1 border-r w-12 text-xs ${appliedSort.some((s) => s.columnKey === "sv_increase_3_mnth_ratio") && "!bg-blue-50"}`}
                                >
                                  <ValueDisplay value={threeMonthVals[0]} />
                                </TableCell>{" "}
                                <TableCell
                                  className={`flex flex-row gap-1 justify-center h-full items-center px-1 w-12 text-xs ${appliedSort.some((s) => s.columnKey === "sv_increase_3_mnth_abs") && "!bg-blue-50"}`}
                                >
                                  <ValueDisplay value={threeMonthVals[1]} />
                                </TableCell>
                              </div>
                            </TableRow>
                          );
                        }
                        if (cellSettings !== null) {
                          if (cellSettings?.showDollar) {
                            return `${cellValue}$`;
                          }
                          if (cellSettings?.isCategory) {
                            const getTextAfterLastPipe = (text: string) => {
                              const parts = text.split("|");
                              return parts.length > 1
                                ? parts[parts.length - 1].trim()
                                : text;
                            };

                            return (
                              <HoverCard>
                                <HoverCardTrigger>
                                  <span
                                    className={
                                      "max-w-[150px] whitespace-break-spaces line-clamp-2 text-center"
                                    }
                                  >
                                    {getTextAfterLastPipe(cellValue)}
                                  </span>
                                </HoverCardTrigger>
                                <HoverCardContent className={"w-full"}>
                                  {cellValue}
                                </HoverCardContent>
                              </HoverCard>
                            );
                          }

                          if (cellSettings?.isSearchTerm) {
                            const link = `https://www.amazon.com/s?k=${cellValue}`;
                            return (
                              <HoverCard>
                                <HoverCardTrigger>
                                  <Button
                                    variant="link"
                                    className={`cursor-pointer text-blue-500 p-0 m-0`}
                                  >
                                    <a
                                      href={link}
                                      target="_blank"
                                      className={`max-w-[150px] whitespace-break-spaces line-clamp-2 ${isOnboardingTrend && ONBOARDING_CLASSES.searchTermStep}`}
                                      rel="noopener noreferrer"
                                    >
                                      {cellValue}
                                    </a>
                                  </Button>
                                </HoverCardTrigger>
                                <HoverCardContent className={"w-full"}>
                                  {cellValue}
                                </HoverCardContent>
                              </HoverCard>
                            );
                          }
                          if (cellSettings?.isChart) {
                            return (
                              <ChartExpander
                                data={cellValue}
                                isOnboardingTrend={isOnboardingTrend}
                              />
                            );
                          }
                          if (
                            cellSettings?.isNumberValueWithKCut ||
                            cellSettings?.isPercent
                          ) {
                            return formatNumber({
                              number: cellValue,
                              isPercentage: cellSettings?.isPercent,
                            });
                          }

                          if (cellSettings?.yesNoValue) {
                            return cellValue ? "Yes" : "No";
                          }

                          if (
                            cellSettings?.isLink &&
                            cellSettings?.hasProductLink
                          ) {
                            if (cellValue === "0") return "-";

                            return (
                              <Button
                                variant="link"
                                className={"text-blue-500 cursor-pointer"}
                              >
                                <a
                                  href={makeLinkFromAsin(cellValue)}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                ></a>
                              </Button>
                            );
                          }
                          if (cellSettings?.isLink && !cellSettings?.isImg) {
                            return (
                              <Button
                                variant="link"
                                className={"cursor-pointer text-blue-500"}
                              >
                                <a
                                  href={cellValue}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {cellValue}
                                </a>
                              </Button>
                            );
                          }
                        }

                        if (
                          cellSettings?.isImg &&
                          cellSettings?.hasProductLink
                        ) {
                          const imageLink = cellValue;

                          return (
                            <TopProductPopover
                              row={row}
                              isOnboarding={isOnboardingTrend}
                            >
                              <Button
                                variant="link"
                                className={`cursor-pointer text-blue-500 h-fit ${isOnboardingTrend && ONBOARDING_CLASSES.topProducts}`}
                              >
                                <a
                                  href={productLink}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {imageLink !== "None" ? (
                                    <img
                                      src={imageLink}
                                      className="max-h-[35px] max-w-[35px] rounded"
                                      width={100}
                                      height={100}
                                      alt={cellValue}
                                    />
                                  ) : (
                                    <img
                                      src={"placeholder-image.png"}
                                      className="max-h-[35px] max-w-[35px] rounded"
                                      width={100}
                                      height={100}
                                      alt={cellValue}
                                    />
                                  )}
                                </a>
                              </Button>
                            </TopProductPopover>
                          );
                        }
                        return cellValue;
                      };

                      return (
                        <TableCell
                          className={`${(isHidden || Object.keys(POPUP_COLUMNS).includes(columnName)) && "hidden"} 
                        border-r-gray-300 ${columnName === "sv_change" && "h-full !pr-0 !p-0 m-0"} border-r !font-xs !font-normal ${columnIsSorted && "bg-blue-50"}`}
                        >
                          <div className="flex flex-col items-center justify-center ">
                            {renderCell()}
                          </div>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>

          <ScrollBar
            forceMount
            orientation="vertical"
            className={"z-[20] !bg-gray-500/50 fixed "}
          />

          <ScrollBar
            orientation="horizontal"
            className="z-[20] !bg-gray-500/50"
          />
        </div>
      </ScrollArea>
    );
  },
);

export default TrendsTable;
