import {
  keepPreviousData,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { TrendsPaginatedResponse } from "@/types/types.ts";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  OptionFilterReturn,
  SortColumnOption,
} from "@/pages/TrendsPage/TrendsPage.tsx";
import { ColumnSettingsByColumnNameInOrder } from "@/hooks/ColumnSettingsByColumnName/ColumnSettingsByColumnNameInOrder.tsx";
import { toast } from "sonner";
import { isObject } from "lodash";
import { useMe } from "@/hooks/useMe.tsx";
import AxiosInstance from "@/services/axios-instance.ts";
import { FilteredColumns } from "@/hooks/usePaginatedTrends/types/FilteredColumns.ts";
import { Filters } from "@/hooks/usePaginatedTrends/types/Filters.ts";
import { useOnboardingStore } from "@/components/onboarding/useOboardingStore.tsx";

export type ClientFilters = Filters | FilteredColumns;

export type ListType = "trends" | "watch" | "black";

export const usePaginatedTrends = ({
  defaultListType = "trends",
}: {
  defaultListType?: ListType;
}) => {
  const [listType, setListType] = useState<ListType>(defaultListType);

  const queryClient = useQueryClient();

  const [appliedFilters, setAppliedFilters] = useState<OptionFilterReturn[]>(
    [],
  );

  const { me } = useMe({});
  const filterList = me?.filter_list;

  useEffect(() => {
    setAppliedFilters(filterList || []);
  }, [filterList]);

  const [appliedSort, setAppliedSort] = useState<SortColumnOption[]>([]);

  const [page, setPage] = useState<number>(1);

  const moveToPaginationPage = (num: number) => {
    setPage(num);
  };

  const hideColumn = (col: FilteredColumns) => {
    if (hiddenRowsIds.includes(col)) {
      const filteredRowsIds = hiddenRowsIds.filter((f) => f !== col);
      setHiddenRowsIds(filteredRowsIds);
      localStorage.setItem("hidden-columns", JSON.stringify(filteredRowsIds));
      return;
    }
    const newHiddenRows = [...hiddenRowsIds, col];
    setHiddenRowsIds(newHiddenRows);
    localStorage.setItem("hidden-columns", JSON.stringify(newHiddenRows));
  };

  const [hiddenRowsIds, setHiddenRowsIds] = useState<FilteredColumns[]>([]);

  useEffect(() => {
    if (listType !== "trends") {
      setAppliedFilters([]);
      setAppliedSort([]);
    }
  }, [listType]);

  useEffect(() => {
    const columns = localStorage.getItem("hidden-columns");
    if (columns) {
      setHiddenRowsIds(JSON.parse(columns));
    }
  }, []);

  const filtersQuery = useMemo(
    () =>
      appliedFilters
        .filter((f) => f.queryKey !== "category_list")
        .map(
          (f) =>
            `${f.queryKey}=${isObject(f.filterValue) ? JSON.stringify(f.filterValue) : f.filterValue}&`,
        )
        .join(""),
    [appliedFilters],
  );

  const sortQuery =
    appliedSort.length !== 0
      ? appliedSort?.map((f) => `${f.columnKey}:${f.sortValue}`).join(",")
      : "";

  const addSortHandler = ({ newSort }: { newSort: SortColumnOption[] }) => {
    setAppliedSort(() => {
      const validNewFilters = newSort.filter((s) => s.sortValue !== 0);
      return [...validNewFilters];
    });
  };

  const addFilterHandler = ({
    newFilters,
  }: {
    newFilters: OptionFilterReturn[];
  }) => {
    if (newFilters.length === 0) {
      setAppliedFilters([]);
      return;
    }

    setAppliedFilters(newFilters);
  };
  const changeListType = useCallback(
    (type: ListType) => {
      // localStorage.setItem(`last-page-${listType}`, page);
      setListType(type);
      void queryClient.invalidateQueries({ queryKey: ["me"] });
    },
    [queryClient],
  );

  // useEffect(() => {
  //   const savedPage = localStorage.getItem(`last-page-${listType}`);
  //   if (savedPage !== null) {
  //     moveToPaginationPage(JSON.parse(savedPage));
  //   }
  // }, []);

  //TODO: edit this with https://tanstack.com/query/v5/docs/framework/react/guides/infinite-queries
  const {
    isPlaceholderData,
    isLoading,
    isFetched,
    isFetching,
    data: paginatedData,
    refetch,
  } = useQuery({
    placeholderData: keepPreviousData,
    queryKey: [`table`, sortQuery, page, listType, filtersQuery],
    queryFn: async ({ signal }) => {
      try {
        const url = ({ page }: { page: number }) =>
          `/trends?limit=85&list_type=${listType}&page=${page}&${filtersQuery}${sortQuery ? `sort=${sortQuery}` : ""}`;
        const resp = await AxiosInstance.get<TrendsPaginatedResponse>(
          url({ page }),
          {
            signal,
          },
        );

        if (resp?.data?.trends?.length === 0 || !resp?.data?.trends) {
          if (listType === "trends") {
            toast.error("No trends, try another filters");
          }
          if (listType === "watch") {
            toast.error("Watchlist is empty");
          }
          if (listType === "black") {
            toast.error("Blacklist is empty");
          }
        }

        //TODO: NEED TO RESOLVE IT
        // if (
        //   resp?.data?.trends?.length === 0 &&
        //   page === resp?.data?.totalPagesCount
        // ) {
        //   const nextPage = page - 1;
        //   setPage(nextPage);
        //   localStorage.setItem(`last-page-${listType}`, nextPage);
        // } else if (resp?.data?.trends?.length === 0) {
        //   toast.error("No trends, try another filters");
        // }

        const sortedTrendsByColumnsOrder = resp?.data?.trends?.map((item) => {
          const sortedItem: any = {};
          Object.keys(ColumnSettingsByColumnNameInOrder).forEach((col) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            sortedItem[col] = item[col];
          });

          return sortedItem;
        });

        const returnedData = {
          ...resp?.data,
          trends: sortedTrendsByColumnsOrder,
        };

        if (resp.data.totalPagesCount > 1) {
          void queryClient.prefetchQuery({
            queryKey: [`table`, sortQuery, page + 1, listType, filtersQuery],
            queryFn: async ({ signal }) => {
              const resp = await AxiosInstance.get<TrendsPaginatedResponse>(
                url({ page: page + 1 }),
                {
                  signal,
                },
              );

              const sortedTrendsByColumnsOrderCahce = resp?.data?.trends?.map(
                (item) => {
                  const sortedItem: any = {};
                  Object.keys(ColumnSettingsByColumnNameInOrder).forEach(
                    (col) => {
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-expect-error
                      sortedItem[col] = item[col];
                    },
                  );

                  return sortedItem;
                },
              );

              const chacheReturnedData = {
                ...resp?.data,
                trends: sortedTrendsByColumnsOrderCahce,
              };

              return chacheReturnedData;
            },
            staleTime: 60000,
          });
        }

        return returnedData;
        // return resp?.data;
      } catch (err: any) {
        if (err?.code === "ERR_CANCELED") return null;
        toast.error(err?.message);
        return null;
      }
    },
    staleTime: 0,
    enabled: me?.subscription,
  });

  const addTrendToWatchList = async ({
    trends_ids,
  }: {
    trends_ids: string[];
  }) => {
    const url = `/set-watch-list`;

    const { onboardingCanShow } = useOnboardingStore.getState();

    try {
      await AxiosInstance.post<{
        trends_ids: string[];
      }>(url, {
        trends_ids,
      });
      await queryClient.cancelQueries({ queryKey: ["me"] });
      queryClient.setQueryData(
        [`table`, sortQuery, page, listType, filtersQuery],
        (old) => {
          const test = old?.trends?.map((trend) =>
            trends_ids.includes(trend.search_term_id)
              ? {
                  ...trend,
                  in_watchlist: true,
                }
              : trend,
          );

          return { ...old, trends: test };
        },
      );

      if (!onboardingCanShow) {
        toast.success("Watchlist updated");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  // Extract the keys and sort them based on columnsOrder
  const tableHeadRow = Object.keys(ColumnSettingsByColumnNameInOrder).map(
    (col) => col,
  ) as FilteredColumns[];

  return {
    addTrendToWatchList,
    changeListType,
    currentPaginationPage: page,
    fetchTable: refetch,
    paginatedData: paginatedData?.trends,
    tableHeadRow,
    isPlaceholderData,
    isLoading,
    isFetched,
    isFetching,
    placeholderData: keepPreviousData,
    addFilterHandler,
    addSortHandler,
    hideColumn,
    hiddenRowsIds,
    moveToPaginationPage,
    totalPagesCount: paginatedData?.totalPagesCount,
    totalRows: paginatedData?.totalRows,
    listType,
    appliedSort,
  };
};
